<template>
  <div>
    <el-dialog v-dialogDrag :title="dialog.title" :visible.sync="dialog.flag" :before-close="handleClose">
      <el-tabs v-if="isFunc" tab-position="left" style="height: 300px;">
        <template v-for="(item,index) in funcs">
          <el-tab-pane v-if="item.visible">
            <span slot="label"><i style="padding-right:5px" class="m-iconfont" :class="item.icon"></i>{{item.name}}</span>
            <component :is="item.funcTag" :uid="deviceUid"></component>
          </el-tab-pane>
        </template>
      </el-tabs>
      <span v-else>暂无可配置项目</span>
    </el-dialog>
  </div>
</template>

<script>

const camPower = () => import("../../components/model/water/camPower.vue")

export default {
  components: {
    camPower, //摄像机供电
  },
  data() {
    return {
      devices: [],
      deviceUid: '', //操作的设备
      dialog: {
        flag: false,
        title: '',
        model: ''
      },
      isFunc: false,
      funcs: [
        {
          visible: false,
          funcTag: 'camPower',
          name: '摄像机供电',
          icon: 'icon-power',
          needDeviceOnline: false,
          needAdminAuth: false,
        },
      ],
      profile: {}
    };
  },
  methods: {
    // 关闭弹窗
    handleClose(e) {
      // 出发通信给父级，可做业务逻辑处理 
      // this.$emit('handleCloseFun')
      // 销毁
      this.dialog.flag = false
      this.dialog.title = null
      this.dialog.model = null
      this.dialog.deviceUid = null
      this.isFunc = false
    },
    //设置funcs是否显示
    setFuncs(model) {
      this.profile = this.$store.state.profile.profile.models;
      let funcs = this.profile[model].funcs
      this.funcs.forEach((item) => {
        if (funcs[item.funcTag] == '1') {
          item.visible = true
          this.isFunc = true
        } else {
          item.visible = false
        }
      })
    }
  },
  props: ['dialogObj'],
  watch: {
    'dialogObj': function (val) {
      this.dialog.flag = val.flag
      this.dialog.title = val.title ? val.title + ' 配置' : val.deviceUid + ' 配置'
      this.deviceUid = val.deviceUid
      this.dialog.model = val.model
      this.setFuncs(val.model)
    }
  },
  created() {

  },
}
</script>

<style>
@import "../../assets/iconfont/icon_menu_list.css";
</style>